<template>
  <section>
    <b-card>
      <b-breadcrumb>
        <div class="d-flex align-items-center">
          <span class="text-primary">Report </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              Report vendite venditori</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
      </b-breadcrumb>
    </b-card>

    <b-row>
      <b-col md="5">
        <b-card>
          <h2>Classifica top 10 venditori</h2>
          <span
            >Di seguito è riportata la classifica aggiornata della top 10 dei
            migliori venditori dell'anno corrente</span
          >
        </b-card>
      </b-col>

      <b-col md="7">
        <b-row>
          <b-col md="12">
            <b-card>
              <h2>La tua scheda venditore</h2>
              <span>Accedi alla tua scheda per monitorare le tue vendite</span>

              <div class="d-flex align-items-end mt-2">
                <b-avatar rounded="sm" size="80"> </b-avatar>
                <b-button class="ml-2" variant="primary"
                  >Vai alla scheda di riepilogo vendite</b-button
                >
              </div>
            </b-card>
          </b-col>

          <b-col md="12">
            <b-card>
              <h2>Cerca per periodo</h2>
              <span
                >Consulta il fatturato del tuo staff in base al periodo
                selezionato</span
              >

              <div class="mt-2 d-flex">

                <v-select>
          
                </v-select>

                <v-select class="mx-2"></v-select>

                <b-button variant="primary">Mostra</b-button>
              </div>
            </b-card>
          </b-col>

          <b-col md="12">
            <b-card>
              <h2>Le ultime 10 vendite</h2>

              <b-row>
                <b-col class="mt-2" v-for="i in 10" :key="i" md="6">
                  <div class="d-flex">
                    <img />

                    <div>

                    <p class="h4">Marca Modello Versione</p>

                    <p class="m-0"><b>Rivenditore:</b> nome rivenditore</p>
                    <p class="m-0"><b>Rivenditore:</b> nome cliente</p>
                    <p class="m-0"><b>Data vendita:</b> gg/mm/aaaa</p>

                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAvatar,
  BButton,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
} from "bootstrap-vue";

import vSelect from "vue-select";

export default {
  components: {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    vSelect,
  },

  data: () => ({}),
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

